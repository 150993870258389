const scrollToElement = require('scroll-to-element');

exports.onRouteUpdate = ({ location }) => {

  const { hash } = location;

  if (!!hash) scrollToElement(hash, {
    offset: -60,
    duration: 1500,
    ease: 'out-bounce',
  });

  function detectScroll() {

    let header = document.getElementById('sticky-header');

    let sections = document.querySelectorAll('section, footer');

    sections.forEach(element => {
      const headerPosition = header.getBoundingClientRect();
      const { top, bottom } = element.getBoundingClientRect();
      const headerHeight = headerPosition.top + headerPosition.bottom;
      if (top <= headerHeight && bottom >= headerHeight) {
        if (!window) return;
        const { backgroundColor, color } = window.getComputedStyle(element);
        header.style.backgroundColor = backgroundColor;
        header.style.color = color;
      }
    });

  }

  detectScroll();

  window.addEventListener('scroll', detectScroll);

}
